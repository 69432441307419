import React, { FC } from "react";

import { ComingSoon } from "../../assets/images";
import { ComingSoonProps } from "../../interfaces";
import { RegisterationModalWrapper, StyledCloseIcon } from "../styled";

const ComingSoonBox: FC<ComingSoonProps> = ({
  closeModal,
  isShowCrossIcon,
}) => {
  return (
    <RegisterationModalWrapper>
      {isShowCrossIcon && (
        <StyledCloseIcon
          onClick={() => {
            if (closeModal) closeModal();
          }}
        />
      )}
      <img src={ComingSoon} alt="comingsoon" />
    </RegisterationModalWrapper>
  );
};

export default ComingSoonBox;
