import React, { FC } from "react";
import { ComingSoon } from "../../assets/images";
import { RegisterationModalProps as PROPS } from "../../interfaces";
import BasicModal from "../BasicModal";
import { RegisterationModalWrapper, StyledCloseIcon } from "../styled";

const Index: FC<PROPS> = ({ isModalOpen, closeModal }) => {
  return (
    <BasicModal open={isModalOpen} onClose={closeModal}>
      <RegisterationModalWrapper>
        <StyledCloseIcon onClick={closeModal} />
        <img src={ComingSoon} alt="comingsoon" />
      </RegisterationModalWrapper>
    </BasicModal>
  );
};

export default Index;
